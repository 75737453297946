import './Section.scss'

const Section = ({ index, title, text, image }) => {

    const invert = index % 2 === 0

    return <div className={`room section ${invert ? 'gray' : ''}`}>

        {!invert && <div className="image-wrapper">
            <img src={image} alt={title} />
        </div>}
        <div className="content">
            <div className="title">{title}</div>
            <div className="text">{text}</div>
        </div>
        {invert && <div className="image-wrapper">
            <img src={image} alt={title} />
        </div>}
    </div>
}

export default Section