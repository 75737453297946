import './DragInactive.scss'
import inactiveImage from '../../../../assets/image/Dropzone/inactive.svg'
import { useSelector } from 'react-redux'

const DropInactive = () => {
    const translation = useSelector(state => state.translation)

    return <div className="drag-inactive">
        <div className="content">
            <div className="image"><img src={inactiveImage} alt={''} /></div>
            <div className="text">{translation['dropzone.inactive.text']}</div>
        </div>
    </div>
}

export default DropInactive