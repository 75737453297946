import './Activation.scss'
import fetch from '../../../fetch'
import Layout from '../../Layout/Layout'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const useQuery = () => new URLSearchParams(useLocation().search);

const Activation = () => {

    const query = useQuery();
    const translation = useSelector(state => state.translation)
    const history = useHistory()


    useEffect(() => {

        const activateAccount = async (emailVerificationToken) => {
            await fetch('/auth/registration/activate', { emailVerificationToken })
        }

        const emailVerificationToken = query.get("token")
        if (emailVerificationToken) {
            activateAccount(emailVerificationToken)
        }
    })

    return <Layout>
        <div id="registration">
            <div className="activation">{translation['registration.activation']}</div>
            <div className="button" onClick={() => history.push('/login')}>{translation['registration.activation.button']}</div>
        </div>
    </Layout>

}

export default Activation