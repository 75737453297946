import './PlanInfo.scss'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { formatFileSize, formatPrice } from '../../../utility'

const PlanInfo = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [plan, setPlan] = useState(0)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)

    useEffect(() => {

        const fetchData = async () => {
            const { status, plan } = await fetch('/user/get/plan', { token })
            if (status && plan) {
                setPlan(plan)
                setLoading(false)
            } else {
                setError(translation['account.planinfo.error'])
            }
        }

        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    return <div id="account-planinfo">
        <div className="subheadline">{translation['account.planinfo']}</div>
        <div className="hint">{translation['account.planinfo.hint']}</div>
        {!loading && <div className="plan-wrapper">
            <div className="name">{plan.name}</div>
            <div className="attribute-list">
                <div>{translation['pricing.plan.file-size']}: {formatFileSize(plan.maxFileSize)}</div>
                <div>{translation['pricing.plan.file-amount']}: {plan.maxFileAmount}</div>
                <div>{translation['pricing.plan.price']}: {formatPrice(plan.priceInCent)}</div>
                <div>{translation['pricing.plan.file-lifetime']}: {plan.fileLifetime.map(lifetime => translation[`filelist.lifetime.${lifetime}`]).join(', ')}</div>
            </div>
        </div>}
        {error && <div className="error">{error}</div>}
    </div>
}

export default PlanInfo