import './File.scss'
import Download from './Download/Download'
import { getPrettyDateTime, formatFileSize } from '../../../../utility'
import infoIcon from '../../../../assets/image/FileList/Info.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const File = ({ file, index }) => {
    const [showInfo, setShowInfo] = useState(false)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const roomSocket = useSelector(state => state.roomSocket)

    const deleteFile = async() => {
        if(token && roomSocket && roomSocket.connected) {
            roomSocket.emit('file-delete', { roomUUID: file.roomUUID, fileUUID: file.uuid, token })
        }
    }

    return <div className={`filelist-file ${index % 2 === 0 ? 'gray' : ''}`}>
        <div className="download-wrapper attr">
            {file.progress !== 100 && `${file.progress} %`}
            {file.progress === 100 && <Download uuid={file.uuid} name={file.name} />}
        </div>
        <div className="name attr">{file.name}</div>
        <div className="size attr">{formatFileSize(file.size)}</div>
        <div className="createdOn attr">{getPrettyDateTime(new Date(file.createdOn))}</div>
        <div className="info attr"><img onClick={() => setShowInfo(!showInfo)} src={infoIcon} alt="Info"/></div>
        {showInfo && <div className="more-info">
            {file.lifetimeString && file.lifetimeString === 'archive' ? <div className="validUntil attr">{translation['filelist.more-info.valid-until']}{translation['filelist.lifetime.archive']}</div> : ''}
            {!file.lifetimeString || file.lifetimeString !== 'archive' ? <div className="validUntil attr">{translation['filelist.more-info.valid-until']}{getPrettyDateTime(new Date(file.validUntil))}</div> : ''}
            {user && user.uuid === file.userUUID ? <div className="delete attr" onClick={deleteFile}>{translation['filelist-upload.remove-file']}</div> : ''}
        </div>}
    </div>
}

export default File
