import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import './Protection.scss'

const Protection = ({ unlock, invalidPassword }) => {
    const translation = useSelector(state => state.translation)
    const [password, setPassword] = useState('')

    const submit = (e) => {
        e.preventDefault()
        unlock(password)
    }

    return <div id="room-protection">
        <div className="modal-wrapper">
            <form onSubmit={submit}>
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>{translation['room-protection.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="room.name">
                            <FormLabel>{translation['room-protection.label']}</FormLabel>
                            <Form.Control maxLength={100} type="password" placeholder={translation['room-protection.placeholder']} value={password} onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                        {invalidPassword && <div className="error">{translation['room-protection.password-incorrect']}</div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" type="submit">{translation['room-protection.unlock']}</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </form>
        </div>
        <div className="filter"></div>
    </div>
}

export default Protection