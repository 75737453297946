import * as types from "../type/types";

export const resetUploads = () => ({
    type: types.RESET_UPLOAD_FILES
})

export const addUploads = (files) => ({
    type: types.ADD_UPLOAD_FILES,
    payload: files
})

export const removeFileFromUpload = index => ({
    type: types.REMOVE_FILE_FROM_UPLOAD,
    payload: index
})

export const setTotalUploadProgress = progress => ({
    type: types.SET_TOTAL_UPLOAD_PROGRESS,
    payload: progress
})

export const setFileList = (files) => ({
    type: types.SET_FILE_LIST,
    payload: files
})

export const upsertFileListItem = file => ({
    type: types.UPSERT_FILE_LIST_ITEM,
    payload: file
})