import "./Navigation.scss";
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import { setUser, setUserToken } from '../../../action/user'
import burgerIcon from '../../../assets/image/Navigation/burger.png'

const Header = () => {
  const user = useSelector(state => state.user)
  const translation = useSelector(state => state.translation)
  const history = useHistory()
  const dispatch = useDispatch()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const redirect = (e) => {
    e.preventDefault()
    history.push(e.target.attributes.href.value)
  }

  const logout = (e) => {
    e.preventDefault()
    const cookies = new Cookies();
    cookies.remove('token', { path: '/' })
    dispatch(setUser(null))
    dispatch(setUserToken(null))
    history.push('/')
  }

  const openMenu = () => setShowMobileMenu(!showMobileMenu)

  return (
    <div id="navigation">
      <div className="mobile-toggle"><div className="item" onClick={openMenu}><img src={burgerIcon} alt="Menu" /></div></div>
      {user && !user.isAnon ? <div className={`item-list ${showMobileMenu && 'show'}`}>
        <a className="item" href="/" onClick={redirect}>{translation['header.navigation.home']}</a>
        <a className="item" href="/account" onClick={redirect}>{translation['header.navigation.account']}</a>
        <a className="item" href="/" onClick={logout}>{translation['header.navigation.logout']}</a>
      </div> : <div className={`item-list ${showMobileMenu && 'show'}`}>
        <a className="item" href="/pricing" onClick={redirect}>{translation['header.navigation.pricing']}</a>
        <a className="item" href="/login" onClick={redirect}>{translation['header.navigation.login']}</a>
      </div>}
    </div>
  );
}

export default Header
