import './Item.scss'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import removeIcon from '../../../../assets/image/RoomOrganizer/remove.png'
import fetch from '../../../../fetch'
import {setList} from '../../../../action/room'

const Item = (props) => {

    const { uuid, name, fileCount } = props.room
    const history = useHistory()
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()

    const remove = async () => {
        const {status, rooms} = await fetch('/user/rooms/remove', {token, roomUUID: uuid})
        if(status && rooms) {
            dispatch(setList(rooms))
        }
    }

    return <div className="room-organizer-item">
        <div className="name attribute" onClick={() => history.push(`/room/${uuid}`)}>{name || translation['room-organizer.room-name-placeholder']}</div>
        <div className="fileCount attribute">{translation['room-organizer.item.files']}{fileCount}</div>
        <div className="remove attribute"><img onClick={remove} src={removeIcon} alt="remove"/></div>
    </div>
}

export default Item