import { backendDomain } from './utility'
import axios from 'axios'

const fetch = async (uriSuffix, body = {}, overwriteURL = false) => {
    try {
        if (!uriSuffix) return false
        const result = await axios.post(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, body)
        return result.data
    } catch (err) {
        return false
    }
}

export const fetchGet = async (uriSuffix, overwriteURL = false, headers = {}) => {
    try {
        if (!uriSuffix) return false
        const result = await axios.get(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, headers)
        return result
    } catch (err) {
        return false
    }
}

export const fileUpload = async (uriSuffix, token, file, progressCallback) => {
    try {
        var formData = new FormData();
        formData.append("file", file);
        const result = await axios.post(`${backendDomain}${uriSuffix}?token=${token}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                if (typeof progressCallback !== 'function') return
                let progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                progressCallback(progress === 100 ? 0 : progress)
            }
        })
        return result.data
    } catch (err) {
        return false
    }
}

export const fileUploadS3 = async (presignedURL, file, progressCallback) => {
    let lastProgress = 0
    const uploadResult = await axios.put((presignedURL),
        file,
        {
            headers: { 'Content-Type': file.type },
            onUploadProgress: (e) => {
                const progress = parseInt(Math.round((e.loaded / e.total) * 100))
                if (typeof progressCallback !== 'function' && progress > lastProgress) return
                lastProgress = progress
                progressCallback(progress)
            }
        }
    );
    return uploadResult.status === 200
}

export default fetch