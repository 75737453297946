import './Registration.scss'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Layout from '../../Layout/Layout'
import Form from './Form/Form'
import Activation from './Activation/Activation'
import { useHistory } from 'react-router-dom'

const Registration = () => {
    const [showActivation, setShowActivation] = useState(false)
    const [finish, setFinish] = useState(false)
    const [mail, setMail] = useState(null)
    const translation = useSelector(state => state.translation)
    const history = useHistory()

    const triggerActivation = (mail) => {
        setMail(mail)
        setShowActivation(true)
    }

    return <Layout>
        <Container>
            <div id="registration">
                {finish && <div className="finish">{translation['registration.redirect-to-login.1']}<span className="link" onClick={() => history.push('/login')}>{translation['registration.redirect-to-login.2']}</span>{translation['registration.redirect-to-login.3']}</div>}
                {!finish && <div className="form-wrapper">
                    <div className="headline">{translation['registration.headline']}</div>
                    <div className="steps">
                        <div className={`step ${!showActivation ? 'active' : ''}`}>
                            <div className="title">{translation['registration.step.account-info']}</div>
                        </div>
                        <div className={`step ${showActivation ? 'active' : ''}`}>
                            <div className="title">{translation['registration.step.activation']}</div>
                        </div>
                    </div>
                    {!showActivation && <Form next={(mail) => triggerActivation(mail)} />}
                    {showActivation && <Activation mail={mail} next={() => setFinish(true)} />}
                </div>}
            </div>
        </Container>
    </Layout>
}

export default Registration