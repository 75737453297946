export const setSocket = (state, action) => {
    return {...state, roomSocket: action.payload}
}

export const setInfo = (state, action) => {
    const roomInfo = state.roomInfo || {}
    return {...state, roomInfo: {...roomInfo, ...action.payload}}
}

export const setList = (state, action) => {
    return {...state, roomList: action.payload}
}