import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import PersonalData from './PersonalData/PersonalData'
import BillingData from './BillingData/BillingData'
import Volume from './Volume/Volume'
import PlanInfo from './PlanInfo/PlanInfo'
import Payment from './PaymentPlan/PaymentPlan'
import Invoice from './Invoice/Invoice'
import Delete from './Delete/Delete'
import './Account.scss'

const Account = () => {

    const translation = useSelector(state => state.translation)

    return <Layout authRequired={true}>
        <Container>
            <div id="account">
                <div className="headline">{translation['account.headline']}</div>
                <div className="content-wrapper">
                    <div className="area-wrapper"><Volume /></div>
                    <div className="area-wrapper"><PlanInfo /></div>
                    <div className="area-wrapper"><Payment /></div>
                    <div className="area-wrapper"><Invoice /></div>
                    <div className="area-wrapper"><BillingData /></div>
                    <div className="area-wrapper"><PersonalData /></div>
                    <div className="area-wrapper"><Delete /></div>
                </div>
            </div>
        </Container>
    </Layout>
}

export default Account