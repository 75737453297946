import fetch from '../../fetch'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CreateRoom = (props) => {

    const history = useHistory()
    const token = useSelector(state => state.token)

    const createRoom = async () => {
        const { status, roomUUID } = await fetch('/room/create', {token})
        if (status && roomUUID) {
            history.push(`/room/${roomUUID}`)
        }
    }

    return <div className="create-room" onClick={createRoom}>{props.children}</div>

}

export default CreateRoom