import './Volume.scss'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { formatFileSize } from '../../../utility'

const Volume = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentFileAmount, setCurrentFileAmount] = useState(0)
    const [totalUsedStorage, setTotalUsedStorage] = useState(0)
    const [usedInPercent, setUsedInPercent] = useState(0)
    const [totalAvailableStorage, setTotalAvailableStorage] = useState(0)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)

    useEffect(() => {

        const fetchData = async () => {
            const { status, volume } = await fetch('/quota', { token })
            if (status && volume && volume.totalUsedStorage != null && volume.currentFileAmount != null && volume.maxFileSize && volume.maxFileAmount) {
                setCurrentFileAmount(volume.currentFileAmount)
                setTotalUsedStorage(volume.totalUsedStorage)
                const totalAvailableStorage = volume.maxFileSize * volume.maxFileAmount
                setTotalAvailableStorage(totalAvailableStorage)
                setUsedInPercent(100 / (totalAvailableStorage) * volume.totalUsedStorage)
                setLoading(false)
            } else {
                setError(translation['account.volume.error'])
            }
        }

        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    return <div id="account-volume">
       <div className="subheadline">{translation['account.volume']}</div>
       <div className="hint">{translation['account.volume.hint']}</div>
      {!loading && <div className="progress-wrapper">
            <div>{`${formatFileSize(totalUsedStorage)} of ${formatFileSize(totalAvailableStorage)} = ${Math.floor(usedInPercent)} %${translation['account.volume.used.label']}`}</div>
            <div>{currentFileAmount}{currentFileAmount === 1 ? translation['account.volume.single-file.label'] : translation['account.volume.multiple-files.label']}</div>
       </div>}
       {error && <div className="error">{error}</div>}
    </div>
}

export default Volume