import './CopyLink.scss'
import icon from '../../assets/image/CopyLink/copy.png'
import check from '../../assets/image/CopyLink/check.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CopyLink = ({ link }) => {

    const translation = useSelector(state => state.translation)
    const [showCopiedLabel, setShowCopiedLabel] = useState(false)

    const copied = () => {
        setShowCopiedLabel(true)
        setTimeout(() => {
            setShowCopiedLabel(false)
        }, 2000)
    }

    if (!link) return ''
    return <CopyToClipboard text={link}
        onCopy={copied}>
        <div className="copylink">
            <div className="text">{link}</div>
            {!showCopiedLabel && <div className="icon"><img src={icon} alt="COPY" /></div>}
            {showCopiedLabel && <div className="icon"><img src={check} alt="COPIED" /></div>}
            <div className="mobile-button button">{translation['copylink.mobile-button']}</div>
        </div>
    </CopyToClipboard>
}

export default CopyLink