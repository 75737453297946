import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Invoice.scss'
import fetch from '../../../fetch'

const Invoice = () => {
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const [error, setError] = useState(null)
    const [invoiceList, setInvoiceList] = useState(null)

    useEffect(() => {
        const getInvoiceList = async() => {
            const { status, list } = await fetch('/payment/stripe/customer/invoice/list', { token })
            if (status && list) {
                setInvoiceList(list)
            } else {
                setError(translation['account.invoice.error'])
            }
        }
        if(token && !invoiceList) {
            getInvoiceList()
        }
    })

    return <div id="account-invoice">
        <div className="subheadline">{translation['account.invoice']}</div>
        {invoiceList && <div className="list">
            {invoiceList.length === 0 && <div className="no-invoice-yet">{translation['account.invoice.no-invoice-yet']}</div>}
            {invoiceList.length > 0 && <div className="item-wrapper">{JSON.stringify(invoiceList)}</div>}
            </div>}
        {error && <div className="error">{error}</div>}
    </div>
}

export default Invoice