import {useEffect} from 'react'
import Cookies from 'universal-cookie';
import {setUserToken, setUser} from '../../action/user'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fetch from '../../fetch'


const Secure = (props) => {

    const authRequired = props.authRequired || false
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const cookies = new Cookies();
        const token = cookies.get('token', { path: '/' })

        const resolveToken = async() => {
            const {status, user, token: newToken} = await fetch('/auth/resolve-token', {token})
            if(status && user) {
                dispatch(setUser(user))
                dispatch(setUserToken(newToken || token))
            } else {
                dispatch(setUser(null))
                dispatch(setUserToken(null))
                cookies.remove('token', {path: '/'})
                if(authRequired) {
                    history.push('/')
                }
            }
        }
        
        if(!user) {
            resolveToken()
        } else if(authRequired && (!user || user.isAnon === true)) {
            dispatch(setUser(null))
            dispatch(setUserToken(null))
            history.push('/')
        }
    })

    return <></>
}

export default Secure