import Layout from '../Layout/Layout'
import './Pricing.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import fetch from '../../fetch'
import Container from "react-bootstrap/Container";
import { formatFileSize, formatPrice, getUUID } from '../../utility'

const Pricing = () => {

    const translation = useSelector(state => state.translation)
    const [planList, setPlanList] = useState(null)
    const history = useHistory()

    useEffect(() => {
        const pullPlanList = async () => {
            const { status, list } = await fetch('/payment/plan/list')
            if (status && Array.isArray(list)) {
                setPlanList(list)
            }
        }

        if (!planList) {
            pullPlanList()
        }
    }, [planList])

    return <div id="pricing">
        <Layout>
            <Container>
                <div className="headline">{translation['pricing.headline']}</div>
                {planList && <div className="planlist">
                    <div className="row">
                        {planList.map(plan => <div key={getUUID()} className="column highlight">{plan.name}</div>)}
                    </div>
                    <div className="attribute">
                        <div className="title">{translation['pricing.plan.file-size']}</div>
                        <div className="hint">{translation['pricing.plan.file-size.hint']}</div>
                    </div>
                    <div className="row">
                        {planList.map(plan => <div key={getUUID()} className="column">{formatFileSize(plan.maxFileSize)}</div>)}
                    </div>
                    <div className="attribute">
                        <div className="title">{translation['pricing.plan.file-amount']}</div>
                        <div className="hint">{translation['pricing.plan.file-amount.hint']}</div>
                    </div>
                    <div className="row">
                        {planList.map(plan => <div key={getUUID()} className="column">
                            <div>{plan.maxFileAmount}</div>
                            <div className="hint">{translation['pricing.plan.total-storage']}{formatFileSize(plan.maxFileAmount * plan.maxFileSize)}</div>
                        </div>)}
                    </div>
                    <div className="attribute">
                        <div className="title">{translation['pricing.plan.file-lifetime']}</div>
                        <div className="hint">{translation['pricing.plan.file-lifetime.hint']}</div>
                    </div>
                    <div className="row">
                        {planList.map(plan => <div key={getUUID()} className="column">{plan.fileLifetime.map(cycle => translation[`filelist.lifetime.${cycle}`]).join(', ')}</div>)}
                    </div>
                    <div className="attribute">
                        <div className="title">{translation['pricing.plan.price']}</div>
                    </div>
                    <div className="row">
                        {planList.map(plan => <div key={getUUID()} className="column price">
                            <div>{formatPrice(plan.priceInCent)}</div>
                            {plan.priceInCent > 0 && <div className="hint">{translation['pricing.plan.price-includes-tax']}</div>}
                        </div>)}
                    </div>
                    <div className="select" onClick={() => history.push('/registration')}>{translation['pricing.plan.cta']}</div>
                </div>}
            </Container>
        </Layout>
    </div>
}

export default Pricing