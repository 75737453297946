import Container from "react-bootstrap/Container";
import "./Footer.scss";
// import { useHistory } from 'react-router-dom'
import {useSelector} from 'react-redux'
// import facebook from "../../assets/image/footer/facebook.png";
// import twitter from "../../assets/image/footer/twitter.png";
// import instagram from "../../assets/image/footer/instagram.png";

const Footer = () => {

  // const history = useHistory()
  const translation = useSelector(state => state.translation)

  // const linkClicked = (e) => {
  //   let link = null
  //   if (e.target.attributes.href) {
  //     link = e.target.attributes.href.value
  //   } else if (e.target.parentElement.attributes.href) {
  //     link = e.target.parentElement.attributes.href.value
  //   }
  //   if (link && link.startsWith('/')) {
  //     e.preventDefault()
  //     history.push(link)
  //     window.scrollTo(0, 0);
  //   }
  // }
  
  const newWindowLinkClicked = (e) => {
    let link = null
    if (e.target.attributes.href) {
      link = e.target.attributes.href.value
    } else if (e.target.parentElement.attributes.href) {
      link = e.target.parentElement.attributes.href.value
    }
    if (link && link.startsWith('/')) {
      e.preventDefault()
      window.open(link, '_blank')
    }
  }

  // const cookieConsent = (e) => {
  //   e.preventDefault()
  //   // show cookie consent to change the cookie settings
  // }

    return (
      <div id="footer">
        {/*<div className="info">
         <Container>
          <div className="block">
            <div><a href="/faq" onClick={linkClicked}>FAQ</a></div>
            <div><a href="/kontakt" onClick={linkClicked}>Kontakt</a></div>
            <div><a href="/" onClick={cookieConsent}>Cookies</a></div>
          </div>
          <div className="block">
            <div><a href="/faq" onClick={linkClicked}>FAQ</a></div>
            <div><a href="/kontakt" onClick={linkClicked}>Kontakt</a></div>
            <div><a href="/" onClick={cookieConsent}>Cookies</a></div>
          </div>
          <div className="block">
            <div><a href="/faq" onClick={linkClicked}>FAQ</a></div>
            <div><a href="/kontakt" onClick={linkClicked}>Kontakt</a></div>
            <div><a href="/" onClick={cookieConsent}>Cookies</a></div>
          </div>
         </Container>
        </div> */}
        <div className="copyright">
          <Container>
            <div>Copyright &copy; Think Internet GmbH {new Date().getFullYear()}</div>
            <div><a href="https://www.think-internet.de/impressum" onClick={newWindowLinkClicked}>{translation['imprint']}</a></div>
            <div><a href="https://www.think-internet.de/datenschutz" onClick={newWindowLinkClicked}>{translation['data-protection']}</a></div>
          </Container>
        </div>
      </div>
    );
}

export default Footer;
