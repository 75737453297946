export const resetUploads = (state) => {
    return {...state, uploadFiles: []}
}

export const addUploads = (state, action) => {
    return {...state, uploadFiles: [...state.uploadFiles, ...action.payload]}
}

export const removeFromUpload = (state, action) => {
    return {...state, uploadFiles: [...state.uploadFiles].filter((file, i) => i !== action.payload)}
}

export const setFileList = (state, action) => {
    return {...state, files: action.payload}
}

export const upsertFileListItem = (state, action) => {
    const files = [...state.files]
    .reduce((acc, file) => {
        if(file.uuid !== action.payload.uuid) {
            acc.push(file)
        }
        return acc
    }, [action.payload])
    .sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
    return {...state, files}
}

export const setTotalUploadProgress = (state, action) => {
    return {...state, totalUploadProgress: action.payload}
}