import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import './Delete.scss'
import fetch from '../../../fetch'
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { setUser, setUserToken } from '../../../action/user'

const Delete = () => {
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const [error, setError] = useState(null)
    const [sure, setSure] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const finalize = async () => {
        const { status } = await fetch('/user/delete', { token })
        if (status) {
            const cookies = new Cookies();
            cookies.remove('token', { path: '/' })
            dispatch(setUser(null))
            dispatch(setUserToken(null))
            history.push('/')
        } else {
            setError(translation['account.delete.error'])
        }
    }

    return <div id="account-delete">
        <div className="subheadline">{translation['account.delete']}</div>
        <div className="hint">{translation['account.delete.hint']}</div>
        {!sure && <div className="sure-cta" onClick={() => setSure(true)}>{translation['account.delete.cta']}</div>}
        {sure && <>
            <div className="sure-yes button" onClick={() => finalize()}>{translation['account.delete.sure-yes']}</div>
            <div className="sure-no button" onClick={() => setSure(false)}>{translation['account.delete.sure-no']}</div>
        </>}
        {error && <div className="error">{error}</div>}
    </div>
}

export default Delete