import {projectName} from '../../utility'

const translations = {

    'project.name': projectName,

    'header.navigation.pricing': 'Pricing',
    'header.navigation.login': 'Login',
    'header.navigation.registration': 'Registration',
    'header.navigation.account': 'Account',
    'header.navigation.rooms': 'Rooms',
    'header.navigation.home': 'Home',
    'header.navigation.logout': 'Logout',

    'home.room.title': 'Rooms',
    'home.room.text': 'Rooms are our way to share files between people. Each room is unique and provides you with a link which can be opened by the people you want to share files with. Users with an account, depending on their tier, are able to secure rooms with a password and set the lifetime of the room and files within that room.',
    'home.speed.title': 'Speed',
    'home.speed.text': 'We\'re using real time communication between server and client to provide everybody with latest information about the uploaded files and even how much already has been uploaded even if you\'re not the one who is uploading.',
    'home.security.title': 'Time restriction',
    'home.security.text': `${projectName} tries to solve the problem of quickly sharing files between users, this is why we've decided to go with an approach to delete the files after a defined timespan automatically. Default is 24 hours but if you've registered you can extend that to 7 days or even 1 month.`,
    'home.intro': 'Share large files instantly with colleagues, friends and familiy!',
    'home.create-room.button': 'Share now!',
    'home.explain': 'A room will be created where you can upload your files instantly. You can share the given link with everybody and they\'ll see what you\'re uploading in real time.',

    'pricing.headline': 'Pricing',
    'pricing.plan.file-size': 'Max File Size',
    'pricing.plan.file-size.hint': 'The size of A SINGLE FILE you can upload.',
    'pricing.plan.file-amount': 'Max File Amount',
    'pricing.plan.file-amount.hint': `The amount of files you can store in parallel across all rooms.`,
    'pricing.plan.file-lifetime': 'File Lifetime Selection',
    'pricing.plan.file-lifetime.hint': `You can decide how long files should exist on ${projectName} until they get deleted automatically.`,
    'pricing.plan.price': 'Monthly Price',
    'pricing.plan.price-includes-tax': '(incl. 19 % tax)',
    'pricing.plan.total-storage': 'Total storage: ',
    'pricing.plan.cta': 'Go to registration',

    'room-organizer.room-name-placeholder': 'Anonymous Room',
    'room-organizer.no-rooms-yet': 'You did not create a room with files yet.',
    'room-organizer.headline': 'Rooms',
    'room-organizer.item.files': 'Files: ',

    'room.loading': 'Preparing room...',
    'room.no-such-room': 'No matching room found :(',
    'room.share-link.text': 'Send this link to everybody you would like to share files with.',
    'room.change-info.button': '(Change Settings)',
    
    'room-info.title': 'Change your room info',
    'room-info.save': 'Save',
    'room-info.close': 'Close',
    'room-info.name.label': 'Name',
    'room-info.name.placeholder': 'Give your room a name.',
    'room-info.description.label': 'Description',
    'room-info.description.placeholder': 'Describe the purpose of your room.',
    'room-info.password.label': 'Password',
    'room-info.password.placeholder': 'Set a password for this room.',
    'room-info.remove-password': 'Remove password',

    'room-protection.title': 'Password protected.',
    'room-protection.label': 'Password',
    'room-protection.placeholder': 'You\'ve to unlock this room.',
    'room-protection.unlock': 'Unlock',
    'room-protection.password-incorrect': 'Password incorrect.',

    'room.chat.input.placeholder': 'Write something...',
    'room.chat.input.placeholder.unable-to-write': 'Only users with an account are able to write messages.',
    'room.chat.message.valid-until': 'Visible until: ',
    'room.chat.message.remove': 'Remove',
    'room.chat.title': 'Live Chat',
    'room.chat.hint': 'Messages have a lifetime of 24 hours.',

    'copylink.mobile-button': 'CLICK TO COPY LINK',
    'copylink.copied-label': 'copied!',

    'dropzone.inactive.text': 'Drop your files here...',
    'dropzone.active.text': 'Let go to add them to your upload list.',

    'filelist.no-files-yet': 'You\'ll see your selected files as well as already uploaded files within this room here.',
    'filelist.start-upload': 'Upload',
    'filelist.lifetime.label': 'Automatic deletion after:',
    'filelist.lifetime.day': '24 hours',
    'filelist.lifetime.week': '1 Week',
    'filelist.lifetime.month': '1 Month',
    'filelist.lifetime.archive': 'Indefinitely',
    'filelist.more-info.valid-until': 'Valid until: ',
    'filelist-upload.remove-file': 'Delete',
    'filelist.limitation.warning-file-size-exceeded': 'At least one file is exceeding the allowed file size for your plan.',
    'filelist.limitation.warning-file-amount-exceeded': 'The amount of files selected exceeds your plan limit.',
    'filelist.limitation.error': 'An unexpected error occurred.',

    'file-download-button': 'Download',

    'login.headline': 'Login',
    'login.mail': 'E-Mail',
    'login.password': 'Password',
    'login.button': 'Login',
    'login.wrong-credentials': 'Username or password incorrect.',
    'login.unknown-error': 'An unexpected error occurred.',
    
    'registration.headline': 'Registration',
    'registration.step.account-info': '1. Account Detail',
    'registration.step.activation': '2. Activation',
    'registration.firstname': 'Firstname',
    'registration.lastname': 'Lastname',
    'registration.mail': 'E-Mail',
    'registration.mail.repeat': 'Confirm E-Mail',
    'registration.password': 'Password',
    'registration.next': 'Next',
    'registration.finish': 'Finish',
    'registration.success': 'Your registration was successful. Please click on the link within the confirmation e-mail we\'ve just sent you.',
    'registration.activation': 'Your account has been activated successfully.',
    'registration.activation.button': 'Login',
    'registration.error.mail-in-use': 'This e-mail address is already in use.',
    'registration.unknown-error': 'An unexpected error occurred.',
    'registration.plan-loading-error': 'Your selected payment plan could not be loaded.',
    'registration.error.check': 'Please fill out all fields and check for accuracy.',
    'activation.label': 'We\'ve sent you an activation code via e-mail, enter it to activate your account.',
    'activation.code': 'Code',
    'activation.error.wrong-code': 'You\'ve entered the wrong activation code.',
    'activation.error.unknown-error': 'An unknown error occurred.',
    'registration.redirect-to-login.1': 'Your activation was successful, click ',
    'registration.redirect-to-login.2': 'here',
    'registration.redirect-to-login.3': ' to login.',
    
    'account.headline': 'Account',
    'account.personal-info': 'Personal Information',
    'account.billing-info': 'Billing Information',
    'account.volume': 'Volume',
    'account.volume.hint': 'Live view of your available storage. Files after their expiration time will be subtracted from your storage.',
    'account.volume.used.label': ' used',
    'account.volume.single-file.label': ' file online',
    'account.volume.multiple-files.label': ' files online',
    'account.volume.error': 'An unexpected error occurred.',
    'account.planinfo': 'Plan Info',
    'account.planinfo.hint': 'Information about your current selected plan.',
    'account.planinfo.error': 'An unexpected error occurred.',
    'account.cta.save': 'Save',
    'account.personal-detail.error': 'An unexpected error occurred.',
    'account.personal-detail.error-check': 'Please check you inputs.',
    'account.mail-update-token.label': 'We\'ve send you the confirmation code to your new email address.',
    'account.mail-update-token.cancel-label': 'Cancel e-mail address update',
    'account.mail-update-token.cancel.error': 'An unexpected error occurred.',
    'account.mail-update-token': 'Confirmation Code',
    'account.payment-detail.selected.label': 'Your selected payment plan is:',
    'account.payment-per-month': ' per Month',
    'account.payment-detail.company': 'Company (optional)',
    'account.payment-detail.vat-id': 'VAT-ID (optional)',
    'account.payment-detail.street': 'Street',
    'account.payment-detail.city': 'City',
    'account.payment-detail.postcode': 'Postcode',
    'account.payment-detail.error': 'An unexpected error occurred.',
    'account.payment-detail.error-check': 'Please check you inputs.',
    'account.payment': 'Plan Upgrade',
    'account.payment.hint': 'You\'re only able to upgrade to a bigger plan than your current one.',
    'account.payment.upgrade.item.cta': 'Upgrade',
    'account.payment.confirm-payment.label': 'Confirm your credit card here:',
    'account.payment.stripe.loading': 'Loading...',
    'account.payment.stripe.customer.create.error': 'Something went wrong in your payment process. Code 1',
    'account.payment.stripe.subscription.create.error': 'Something went wrong in your payment process. Code 2',
    'account.payment.stripe.unexpected.error': 'Something went wrong in your payment process. Code 3',
    'account.payment.error': 'An unexpected error occurred.',
    'account.payment.billing-data-hint': 'Please check your billing information below to make sure all your invoices contain accurate information.',
    'account.payment.pending-payment-hint': 'Your card verification was successful! It might take a couple of minutes to activate your new plan.',
    'account.payment.select-plan-dropdown-label': 'Select Plan',
    'account.payment.biggest-plan-reached': 'Your already subscribed to the biggest available plan.',
    'account.payment.billing-data-status-negative': 'Please fullfill your billing information first. Than reload this page.',
    'account.payment.finish': 'Confirm Upgrade',
    'account.invoice': 'Invoices',
    'account.invoice.error': 'An unexpected error occurred.',
    'account.invoice.no-invoice-yet': 'No invoice available yet.',
    'account.delete': 'Delete',
    'account.delete.hint': 'Nobody is able to restore any of your data if you delete your account.',
    'account.delete.cta': 'Delete my account and all my data',
    'account.delete.sure-no': 'Cancel',
    'account.delete.sure-yes': 'Delete everything!',
    'account.delete.error': 'An unexpected error occurred.',

    'imprint': 'Imprint',
    'data-protection': 'Data Protection'

}

export const langKey = 'EN'

export default translations