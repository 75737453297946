import './DragActive.scss'
import activeImage from '../../../../assets/image/Dropzone/active.svg'
import { useSelector } from 'react-redux'

const DragActive = () => {
    const translation = useSelector(state => state.translation)

    return <div className="drag-active">
        <div className="content">
            <div className="image"><img src={activeImage} alt={''} /></div>
            <div className="text">{translation['dropzone.active.text']}</div>
        </div>
    </div>
}

export default DragActive