import './Login.scss'
import Form from 'react-bootstrap/Form'
import { setUserToken, setUser } from '../../../action/user'
import Cookies from 'universal-cookie';
import fetch from '../../../fetch'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import Layout from '../../Layout/Layout'


const Login = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const translation = useSelector(state => state.translation)
    const cookies = new Cookies();

    const submit = async (e) => {
        e.preventDefault()
        if (mail.length > 0 && password.length > 0) {
            setError(null)
            const { status, token, user } = await fetch('/auth/login', { mail, password })
            if (status && token && user) {
                dispatch(setUserToken(token))
                dispatch(setUser(user))
                cookies.set('token', token, { path: '/' })
                history.push('/')
            } else {
                setError(translation['login.wrong-credentials'])
            }
        } else {
            setError(translation['login.unknown-error'])
        }
    }

    return <Layout>
        <div id="login">
        <div className="form-wrapper">
            <div className="headline">{translation['login.headline']}</div>
            <Form onSubmit={submit}>
                <Form.Group controlId="login.email">
                    <Form.Control type="email" placeholder={translation['login.mail']} value={mail} onChange={e => setMail(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="login.password">
                    <Form.Control type="password" placeholder={translation['login.password']} value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <button type="submit" className="button">{translation['login.button']}</button>
            </Form>
            {error ? <div className="error">{error}</div> : ''}
        </div>
    </div>
    </Layout>
}

export default Login