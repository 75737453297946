import './QuickStart.scss'
import { useSelector } from 'react-redux'
import CreateRoom from '../../CreateRoom/CreateRoom'

const RoomOrganizer = () => {

    const translation = useSelector(state => state.translation)

    return <div id="quick-start">
        <div className="intro">{translation['home.intro']}</div>
        <div className="button-wrapper">
            <CreateRoom><div className="button">{translation['home.create-room.button']}</div></CreateRoom>
        </div>
        <div className="explain">{translation['home.explain']}</div>
    </div>
}

export default RoomOrganizer