import './BillingData.scss'
import Form from 'react-bootstrap/Form'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

const BillingData = () => {

    const [company, setCompany] = useState('')
    const [vatId, setVatId] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [postcode, setPostcode] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)

    useEffect(() => {

        const fetchData = async () => {
            const { status, data } = await fetch('/user/get/billing-data', { token })
            if (status && data) {
                setCompany(data.company)
                setVatId(data.vatId)
                setStreet(data.street)
                setCity(data.city)
                setPostcode(data.postcode)
                setLoading(false)
            } else {
                setError(translation['account.payment-detail.error'])
            }
        }

        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    const submit = async (e) => {
        e.preventDefault()
        setError(null)
        if (street && street.length > 0 && city && city.length > 0 && postcode && postcode.length > 0) {
            const { status } = await fetch('/user/update/billing-data', { token, company, vatId, street, city, postcode })
            if (!status) {
                setError(translation['account.payment-detail.error'])
            }
        } else {
            setError(translation['account.payment-detail.error-check'])
        }
    }

    return <div id="account-payment-detail">
        <div className="subheadline">{translation['account.billing-info']}</div>
        <div className="form-wrapper">
            {!loading && <form onClick={submit}>
                <Form.Group controlId="account.payment.company">
                    <Form.Control type="text" placeholder={translation['account.payment-detail.company']} value={company} onChange={e => setCompany(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.payment.vat-id">
                    <Form.Control type="text" placeholder={translation['account.payment-detail.vat-id']} value={vatId} onChange={e => setVatId(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.payment.street">
                    <Form.Control type="text" placeholder={translation['account.payment-detail.street']} value={street} onChange={e => setStreet(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.payment.city">
                    <Form.Control type="text" placeholder={translation['account.payment-detail.city']} value={city} onChange={e => setCity(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.payment.postcode">
                    <Form.Control type="text" placeholder={translation['account.payment-detail.postcode']} value={postcode} onChange={e => setPostcode(e.target.value)} />
                </Form.Group>
                <div className="button-group">
                    <div className="button-wrapper"><button type="submit" className="button">{translation['account.cta.save']}</button></div>
                </div>
            </form>}
            {error ? <div className="error">{error}</div> : ''}
        </div>
    </div>
}

export default BillingData