import { useSelector } from 'react-redux'
import { formatFileSize, formatPrice } from '../../../../utility'
import './PlanDetail.scss'

const PaymentPlanItem = ({ plan, select }) => {
    const translation = useSelector(state => state.translation)

    return <div className="account-paymentplan-item">
        <div className="group">
            <div className="label">{translation['pricing.plan.file-size']}</div>
            <div className="attr">{formatFileSize(plan.maxFileSize)}</div>
        </div>
        <div className="group">
            <div className="label">{translation['pricing.plan.file-amount']}</div>
            <div className="attr">{plan.maxFileAmount}</div>
        </div>
        <div className="group">
            <div className="label">{translation['pricing.plan.file-lifetime']}</div>
            <div className="attr">{plan.fileLifetime.map(lifetime => translation[`filelist.lifetime.${lifetime}`]).join(', ')}</div>
        </div>
        <div className="group">
            <div className="label">{translation['pricing.plan.price']}</div>
            <div className="attr">{formatPrice(plan.priceInCent)} {translation['pricing.plan.price-includes-tax']}</div>
        </div>
        <div className="button select" onClick={() => select(plan.code)}>{translation['account.payment.upgrade.item.cta']}</div>
    </div>
}

export default PaymentPlanItem