import './RoomOrganizer.scss'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect} from 'react'
import fetch from '../../../fetch'
import {getUUID} from '../../../utility'
import Item from './Item/Item'
import Container from "react-bootstrap/Container";
import {setList} from '../../../action/room'

const RoomOrganizer = () => {

    const token = useSelector(state => state.token)
    const translation = useSelector(state => state.translation)
    const roomList = useSelector(state => state.roomList)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchRooms = async() => {
            const {status, rooms} = await fetch('/user/rooms/list', {token})
            if(status && rooms) {
                dispatch(setList(rooms))
            }
        }

        if(token) {
            fetchRooms()
        }
    }, [token, dispatch])

    return <Container><div id="roomOrganizer">
        <div className="headline">{translation['room-organizer.headline']}</div>
        {!Array.isArray(roomList) || roomList.length === 0 ? <div className="no-rooms-yet">{translation['room-organizer.no-rooms-yet']}</div> : ''}
        <div className="room-list">{Array.isArray(roomList) && roomList.length > 0 ? roomList.map(room => <Item key={getUUID()} room={room}/>) : ''}</div>
    </div></Container>
}

export default RoomOrganizer