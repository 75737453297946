import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Consent from './components/Consent/Consent'
import Home from './components/Home/Home'
import Pricing from './components/Pricing/Pricing'
import Room from './components/Room/Room'
import Account from './components/Account/Account'
import DataProtection from './components/DataProtection/DataProtection'
import Imprint from './components/Imprint/Imprint'
import Login from './components/Auth/Login/Login'
import Registration from './components/Auth/Registration/Registration'
import RegistrationActivation from './components/Auth/Activation/Activation'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Consent />
      <Router>
        <Switch>
          {/* LANDINGPAGE */}
          <Route exact={true} path="/">
              <Home />
          </Route>
          {/* PRICING */}
          <Route exact={true} path="/pricing">
              <Pricing />
          </Route>
          {/* ROOM */}
          <Route exact={true} path="/room/:uuid">
              <Room />
          </Route>
          {/* LOGIN */}
          <Route exact={true} path="/login">
              <Login />
          </Route>
          {/* REGISTRATION */}
          <Route exact={true} path="/registration">
              <Registration />
          </Route>
          {/* REGISTRATION */}
          <Route exact={true} path="/registration/activation">
              <RegistrationActivation />
          </Route>
          {/* ACCOUNT */}
          <Route exact={true} path="/account">
              <Account />
          </Route>
          {/* IMPRINT */}
          <Route exact={true} path="/imprint">
              <Imprint />
          </Route>
          {/* DATA PROTECTION */}
          <Route exact={true} path="/data-protection">
              <DataProtection />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
