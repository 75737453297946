import "./Header.scss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Navigation from './Navigation/Navigation.js'

const Header = () => {
  const translation = useSelector(state => state.translation)
  const history = useHistory()

  return (
    <div id="header">
      <div className="logo" onClick={() => history.push('/')}>{translation['project.name']}</div>
      <div className="navigation-wrapper">
        <Navigation />
      </div>
    </div>
  );
}

export default Header
