import './UploadFileList.scss'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUUID } from '../../../utility'
import File from './File/File'
import { processFiles } from '../../../helper/room/fileUpload'
import { setTotalUploadProgress, resetUploads } from '../../../action/file'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Lifetime from './Lifetime/Lifetime'
import UploadButton from './UploadButton/UploadButton'

const UploadFileList = () => {
    const uploadFiles = useSelector(state => state.uploadFiles)
    const roomInfo = useSelector(state => state.roomInfo)
    const socket = useSelector(state => state.roomSocket)
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const totalUploadProgress = useSelector(state => state.totalUploadProgress)
    const [locked, setLocked] = useState(false)
    const [uploadLifetimeOptions, setUploadLifetimeOptions] = useState(null)
    const [selectedLifetimeOption, setSelectedLifetimeOption] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setSelectedLifetimeOption('day')
        if(user && user.planInfo) {
            setUploadLifetimeOptions(user.planInfo.fileLifetime)
        } else {
            setUploadLifetimeOptions(['day'])
        }
    }, [user])

    const upload = async () => {
        setLocked(true)
        await processFiles(socket, roomInfo.uuid, token, uploadFiles, selectedLifetimeOption, totalProgress => {
            dispatch(setTotalUploadProgress(totalProgress))
        })
        dispatch(resetUploads())
        setLocked(false)
    }

    if (totalUploadProgress >= 0 && locked) {
        return <div className="upload-filelist">
            <div className="progress-wrapper" style={{ width: 150 }}>
                <CircularProgressbar value={totalUploadProgress} text={`${totalUploadProgress} %`} styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'round',
                    textSize: '20px',
                    pathTransitionDuration: 0.2,
                    pathColor: `#808080`,
                    textColor: 'rgb(204, 0, 0)',
                    trailColor: 'rgb(204, 0, 0)',
                })} />
            </div>
        </div>
    }
    if(locked) return ''
    return <div className="upload-filelist">
        {Array.isArray(uploadFiles) && uploadFiles.length > 0 && Array.isArray(uploadLifetimeOptions) ? <>
            <div className="list">{uploadFiles.map((file, i) => <File key={getUUID()} index={i} file={file} />)}</div>
            <div className="lifetime-wrapper"><Lifetime options={uploadLifetimeOptions} selectedOption={selectedLifetimeOption} onChange={setSelectedLifetimeOption} /></div>
            <div className="upload-button-wrapper"><UploadButton upload={upload} /></div>
        </> : ''}
    </div>
}

export default UploadFileList