import './PersonalData.scss'
import Form from 'react-bootstrap/Form'
import fetch from '../../../fetch'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const PersonalData = () => {

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [mail, setMail] = useState('')
    const [mailUpdate, setMailUpdate] = useState(false)
    const [mailUpdateValidationCode, setMailUpdateValidationCode] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)


    useEffect(() => {
        const fetchData = async() => {
            setError(null)
            const { status, data } = await fetch('/user/get/personal-data', { token })
            if (status && data) {
                setFirstname(data.firstname)
                setLastname(data.lastname)
                setMail(data.mail)
                setPassword('')
                if (data.mailUpdate && data.mailUpdate.length > 0) {
                    setMailUpdate(data.mailUpdate)
                } else {
                    setMailUpdate(false)
                }
                setLoading(false)
            } else {
                setError(translation['account.personal-detail.error'])
            }
        }

        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    const submit = async (e) => {
        e.preventDefault()
        setError(null)
        if (firstname.length > 0 && lastname.length > 0 && mail.length > 0) {
            const { status } = await fetch('/user/update/personal-data', { token, firstname, lastname, mail, password, mailUpdateValidationCode })
            if (!status) {
                setError(translation['account.personal-detail.error'])
            } else {
                setLoading(true)
            }
        } else {
            setError(translation['account.personal-detail.error-check'])
        }
    }

    const revertMailUpdate = async () => {
        const { status } = await fetch('/user/update/cancel-mail-update', { token })
        if (status) {
            setMailUpdate(null)
        } else {
            setError(translation['account.mail-update-token.cancel.error'])
        }
    }

    return <div id="account-personal-info">
        <div className="subheadline">{translation['account.personal-info']}</div>
        <div className="form-wrapper">
            {!loading && <form onSubmit={submit}>
                <Form.Group controlId="account.firstname">
                    <Form.Control type="text" placeholder={translation['registration.firstname']} value={firstname} onChange={e => setFirstname(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.lastname">
                    <Form.Control type="text" placeholder={translation['registration.lastname']} value={lastname} onChange={e => setLastname(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="account.email">
                    {!mailUpdate && <Form.Control type="email" placeholder={translation['registration.mail']} value={mail} onChange={e => setMail(e.target.value)} />}
                    {mailUpdate && <>
                        <div className="plain-mail">{`${mail} -> ${mailUpdate}`}</div>
                        <div className="mail-update-token-label">{translation['account.mail-update-token.label']}</div>
                        <Form.Control type="text" placeholder={translation['account.mail-update-token']} value={mailUpdateValidationCode} onChange={e => setMailUpdateValidationCode(e.target.value)} />
                        <div className="mail-update-token-cancel" onClick={revertMailUpdate}>{translation['account.mail-update-token.cancel-label']}</div>
                    </>}
                </Form.Group>
                <Form.Group controlId="account.password">
                    <Form.Control type="password" placeholder={translation['registration.password']} value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <button type="submit" className="button">{translation['account.cta.save']}</button>
            </form>}
            {error ? <div className="error">{error}</div> : ''}
        </div>
    </div>
}

export default PersonalData