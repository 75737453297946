import './Limitation.scss'
import {useSelector} from 'react-redux'
import { useEffect, useState } from 'react'
import fetch from '../../../../../fetch'

const UploadFileListLimitation = ({children}) => {

    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [availableFileAmount, setAvailableFileAmount] = useState(null)
    const [maxFileSize, setMaxFileSize] = useState(null)
    const uploadFiles = useSelector(state => state.uploadFiles)

    useEffect(() => {
        const fetchData = async () => {
            const { status, volume } = await fetch('/quota', { token })
            if (status && volume && volume.totalUsedStorage != null && volume.currentFileAmount != null && volume.maxFileSize && volume.maxFileAmount) {
                console.log(volume.maxFileAmount - volume.currentFileAmount)
                setAvailableFileAmount(volume.maxFileAmount - volume.currentFileAmount)
                setMaxFileSize(volume.maxFileSize)
                setLoading(false)
            } else {
                setError(translation['filelist.limitation.error'])
            }
        }
        
        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    const exceedsMaxFileSize = () => {
        return uploadFiles.reduce((acc, file) => {
            return acc === true ? true : file.size > maxFileSize
        }, false)
    }

    const exceedsMaxFileAmount = () => {
        return uploadFiles.length > availableFileAmount
    }

    return <div className="upload-filelist-limitation">
        {!loading && !error && exceedsMaxFileAmount() ? <div className="warning">{translation['filelist.limitation.warning-file-amount-exceeded']}</div> : ''}
        {!loading && !error && exceedsMaxFileSize() ? <div className="warning">{translation['filelist.limitation.warning-file-size-exceeded']}</div> : ''}
        {!loading && !error && !exceedsMaxFileAmount() && !exceedsMaxFileSize()  ? <div>{children}</div> : ''}
       {error && <div className="error">{error}</div>}
    </div>
}

export default UploadFileListLimitation