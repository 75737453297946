import './Lifetime.scss'
import {useSelector} from 'react-redux'
import {getUUID} from '../../../../utility'

const UploadFileListLifetime = ({options, selectedOption, onChange}) => {

    const translation = useSelector(state => state.translation)

    return <div className="upload-filelist-lifetime">
        <div className="label">{translation['filelist.lifetime.label']}</div>
        <select onChange={(e) => onChange(e.target.value)} value={selectedOption}>
            {options.map(option => <option key={getUUID()} value={option}>{translation[`filelist.lifetime.${option}`]}</option>)}
        </select>
    </div>
}

export default UploadFileListLifetime