import './Input.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Input = () => {
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const socket = useSelector(state => state.roomSocket)
    const [message, setMessage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const user = useSelector(state => state.user)

    const maxLength = 5000

    useEffect(() => {
        if (socket && socket.connected && user && !user.isAnon) {
            setDisabled(false)
        }
    }, [socket, setDisabled, user])

    const sendMessage = () => {
        if (message.length > 0 && message.length <= maxLength) {
            socket.emit('chat-message', { token, message })
            setMessage('')
        }
    }

    const inputValidator = e => {
        if (e.key === 'Enter') {
            sendMessage()
        }
    }

    return <div className='chat-input'>
        <input type="text" maxLength={maxLength} disabled={disabled} placeholder={disabled ? translation['room.chat.input.placeholder.unable-to-write'] : translation['room.chat.input.placeholder']} onKeyDown={inputValidator} onChange={e => setMessage(e.target.value)} value={message} />
    </div>
}

export default Input
