import Layout from '../Layout/Layout'
import QuickStart from './QuickStart/QuickStart'
import './Home.scss'
import Sections from './Sections/Sections'
import {useSelector} from 'react-redux'
import RoomOrganizer from './RoomOrganizer/RoomOrganizer'

const Home = () => {

    const user = useSelector(state => state.user)

    return <div id="home">
        <Layout>
            <div className="quickstart-wrapper"><QuickStart /></div>
            {user && !user.isAnon ? <RoomOrganizer/> : <Sections/>}
        </Layout>
    </div>
}

export default Home