import './Room.scss'
import Dropzone from './Dropzone/Dropzone'
import { useParams } from 'react-router-dom'
import { useEffect, useLayoutEffect, useState } from 'react'
import UploadFileList from './UploadFileList/UploadFileList'
import FileList from './FileList/FileList'
import Chat from './Chat/Chat'
import Layout from '../Layout/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { backendSocketIODomain, frontendDomain } from '../../utility'
import socketIOClient from "socket.io-client";
import { setSocket, setInfo } from '../../action/room'
import { setFileList } from '../../action/file'
import NoSuchRoom from './NoSuchRoom/NoSuchRoom'
import CopyLink from '../CopyLink/CopyLink'
import InfoEdit from './InfoEdit/InfoEdit'
import Protection from './Protection/Protection'

const Room = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const socket = useSelector(state => state.roomSocket)
    const uploadFiles = useSelector(state => state.uploadFiles)
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const [exists, setExists] = useState(null)
    const [showInfoEdit, setShowInfoEdit] = useState(false)
    const roomInfo = useSelector(state => state.roomInfo)
    const [showPasswordProtection, setShowPasswordProtection] = useState(false)
    const [invalidPassword, setInvalidPasword] = useState(false)
    const [roomUUID, setRoomUUID] = useState(null)

    useEffect(() => {
        setRoomUUID(params.uuid)

        const init = async () => {
            const socket = socketIOClient(backendSocketIODomain)

            socket.on('connect', () => {
                dispatch(setSocket(socket))
                socket.emit('init')
                socket.on('init', (status) => {
                    if (status) {
                        socket.emit('room-info', roomUUID)
                    } else {
                        console.log('here1');
                        socket.disconnect()
                    }
                })
                socket.on('room-info', (info) => {
                    if (typeof info === 'object') {
                        if (info.secured) {
                            setShowPasswordProtection(true)
                        } else {
                            socket.emit('room-unlock', { roomUUID, token })
                        }
                    } else {
                        setExists(false)
                    }
                })
                socket.on('room-unlocked', (room) => {
                    if (room) {
                        setShowPasswordProtection(false)
                        dispatch(setInfo(room))
                        setExists(true)
                        setInvalidPasword(false)
                    } else {
                        setInvalidPasword(true)
                    }
                })
                socket.on('file-collection', files => dispatch(setFileList(files)))
                socket.on('room-info-change', roomInfo => dispatch(setInfo(roomInfo)))
            })
            socket.on('disconnect', () => {
                dispatch(setSocket(null))
                dispatch(setFileList(null))
                dispatch(setInfo(null))
            })
        }

        if (!socket && (exists === null || exists === true)) {
            init()
        }
    }, [socket, dispatch, params, token, roomUUID, exists])

    useLayoutEffect(() => {
        return () => {
            if (socket) {
                if (socket.connected) {
                    socket.disconnect()
                }
                dispatch(setSocket(null))
                dispatch(setFileList(null))
                dispatch(setInfo(null))
            }
        }
    }, [dispatch, socket])

    const unlockRoom = (password) => {
        if (socket && socket.connected) {
            socket.emit('room-unlock', { roomUUID, password, token })
        }
    }

    return <Layout>
        <div id="room">
            {showPasswordProtection && <Protection invalidPassword={invalidPassword} unlock={unlockRoom} />}
            {exists === true && socket && !showPasswordProtection && <>
                <div className="name-description">
                    <div className="name">{roomInfo && roomInfo.name ? roomInfo.name : translation['room-organizer.room-name-placeholder']}</div>
                    {roomInfo && roomInfo.description ? <div className="description">{roomInfo.description}</div> : ''}
                    {roomInfo && roomInfo.isOwner ? <span className="change-settings" onClick={() => setShowInfoEdit(true)}>{translation['room.change-info.button']}</span> : ''}
                </div>
                <div className="link">
                    <CopyLink link={`${frontendDomain}/room/${params.uuid}`} />
                    <div className="share-text">{translation['room.share-link.text']}</div>
                </div>
                <div className="flex">
                    <div className="dropzone-wrapper">
                        <Dropzone />
                    </div>
                    <div className="fileList-wrapper">
                        {Array.isArray(uploadFiles) && uploadFiles.length > 0 ? <UploadFileList /> : ''}
                        <FileList />
                    </div>
                </div>
                <div className="chat-wrapper">
                    <Chat />
                </div>
            </>}
            {exists === null && <div className="loading">{translation['room.loading']}</div>}
            {exists === false && <NoSuchRoom />}
            {showInfoEdit && <InfoEdit close={() => setShowInfoEdit(false)} />}
        </div>
    </Layout>


}

export default Room