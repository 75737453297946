import './Layout.scss'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Secure from '../Secure/Secure'
import { stripe_publishable_key } from '../../utility'
import { Elements } from '@stripe/react-stripe-js'

import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(stripe_publishable_key)

const Layout = (props) => {

    const header = props.header !== undefined ? props.header : true
    const footer = props.footer !== undefined ? props.footer : true

    return <Elements stripe={stripePromise}>
        <Secure authRequired={props.authRequired} />
        {header && <Header />}
        <div id="content-container">
            {props.children}
        </div>
        {footer && <Footer />}
    </Elements>
}

export default Layout