import './Download'
import { useSelector } from 'react-redux'
import { getPresignedDownloadURLFromS3BucketEndpoint } from '../../../../../utility'
import fetch from '../../../../../fetch'

const Download = ({ uuid, name }) => {
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const roomInfo = useSelector(state => state.roomInfo)

    const getPresignedURL = async () => {
        if (roomInfo && roomInfo.uuid && uuid && name) {
            const { status, message } = await fetch('/room/file/download', { roomUUID: roomInfo.uuid, fileUUID: uuid, token })
            if (status && message) {
                const response = await fetch(getPresignedDownloadURLFromS3BucketEndpoint, { message }, true);
                if (response && response.downloadURL) {
                    return response.downloadURL
                }
            }
            return false
        }
    }

    const download = async () => {
        const downloadURL = await getPresignedURL()
        if (downloadURL) {
            const link = document.createElement('a');
            link.href = downloadURL;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }
    }

    return <div className="download button" onClick={download}>{translation['file-download-button']}</div>
}

export default Download