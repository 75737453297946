import './PaymentPlan.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Stripe from './Stripe/Stripe'
import PlanDetail from './PlanDetail/PlanDetail'
import fetch from '../../../fetch'
import Dropdown from 'react-bootstrap/Dropdown'
import { getUUID } from '../../../utility'

const PaymentPlan = () => {
    const translation = useSelector(state => state.translation)
    const token = useSelector(state => state.token)
    const [planList, setPlanList] = useState(null)
    const [error, setError] = useState(null)
    const [planUpgrade, setPlanUpgrade] = useState(null)
    const [loading, setLoading] = useState(true)
    const [upgradeSuccessful, setUpgradeSuccessful] = useState(false)
    const [dropdownPlanSelection, setDropdownPlanSelection] = useState(null)
    const [billingDataStatus, setBillingDataStatus] = useState(null)

    useEffect(() => {

        const fetchData = async () => {
            const { status: listStatus, list } = await fetch('/payment/plan/list')
            const { status: planStatus, plan } = await fetch('/user/get/plan', { token })
            const { status: billingDataStatusResponseStatus, billingDataStatus } = await fetch('/user/get/billing-data-status', { token })
            if (listStatus && Array.isArray(list) && planStatus && plan && billingDataStatusResponseStatus) {
                setBillingDataStatus(billingDataStatus)
                setPlanList(list.filter(listPlan => listPlan.priceInCent > plan.priceInCent))
                setLoading(false)
            } else {
                setError(translation['account.payment.error'])
            }
        }

        if (loading && token) {
            fetchData()
        }
    }, [loading, token, translation])

    const finishUpgrade = () => {
        setUpgradeSuccessful(true)
        setPlanUpgrade(null)
    }

    if (loading) return <div></div>
    return <div id="account-paymentplan">
        <div className="subheadline">{translation['account.payment']}</div>
        <div className="hint">{translation['account.payment.hint']}</div>
        {!loading && !billingDataStatus ? <div className="biggest-plan-reached">{translation['account.payment.billing-data-status-negative']}</div> : ''}
        {!loading && billingDataStatus ? <>
            {planList.length === 0 && <div className="biggest-plan-reached">{translation['account.payment.biggest-plan-reached']}</div>}
            {planList.length > 0 && <>
                <div className="plan-selection">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {dropdownPlanSelection && dropdownPlanSelection.name ? dropdownPlanSelection.name : translation['account.payment.select-plan-dropdown-label']}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {planList.map(plan => <Dropdown.Item key={getUUID()} onClick={() => setDropdownPlanSelection(plan)}>{plan.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {dropdownPlanSelection && <div className="plan-selection-item">
                    <PlanDetail plan={dropdownPlanSelection} select={setPlanUpgrade} />
                </div>}
                {upgradeSuccessful && <div className="pending-payment">{translation['account.payment.pending-payment-hint']}</div>}
                {planUpgrade && <div className="billing-data-hint">{translation['account.payment.billing-data-hint']}</div>}
                {planUpgrade && <Stripe planCode={planUpgrade} finish={finishUpgrade} />}
            </>}
        </> : ''}
        {error && <div className="error">{error}</div>}
    </div>
}

export default PaymentPlan