// implement a list (+ report-list) and delete function for newsletters
// implement a press message list with delete function
// implement a job list with delete function
// implement the consent features with the function from usercentrics -> maybe create a homepage for it

import React, {Component} from 'react'
import Cookies from 'universal-cookie';

class Consent extends Component {
    componentDidMount() {
        const cookies = new Cookies();
        const consent = cookies.get('consent');
        console.log(consent);
    }

    render() {
        return <></>
    }
}

export default Consent