import Section from './Section/Section'
import roomImage from '../../../assets/image/Home/room.svg'
import speedImage from '../../../assets/image/Home/speed.svg'
import securityImage from '../../../assets/image/Home/security.svg'
import { useSelector } from 'react-redux'
import { getUUID } from '../../../utility'

const Sections = () => {

    const translation = useSelector(state => state.translation)
    const sections = [
        {
            title: translation['home.room.title'],
            text: translation['home.room.text'],
            image: roomImage
        },
        {
            title: translation['home.speed.title'],
            text: translation['home.speed.text'],
            image: speedImage
        },
        {
            title: translation['home.security.title'],
            text: translation['home.security.text'],
            image: securityImage
        },
    ]

    return <div className="sections">
        {sections.map((section, i) => <Section key={getUUID()} index={i} title={section.title} text={section.text} image={section.image}/>)}
    </div>
}

export default Sections