import { getPresignedUploadURLFromS3BucketEndpoint } from '../../utility'
import fetch, { fileUploadS3 } from '../../fetch'

const getPresignedURL = async (message) =>
    new Promise(async (resolve, reject) => {
        const response = await fetch(getPresignedUploadURLFromS3BucketEndpoint,
            {
                message
            }, true);
        if (response && response.uploadURL) {
            resolve(response)
        } else {
            reject()
        }
    })

const createFile = (file) =>
    new Promise(async (resolve, reject) => {
        const { status, message } = await fetch('/room/file/create', file)
        if (status && message) {
            if(message === 'file-size-exceeded') {
                reject(message)
            } else {
                resolve(message)
            }
        } else {
            reject()
        }
    })

const calculateTotalUploadSize = (files) => {
    const totalUploadSize = files.reduce((acc, file) => acc += file.size, 0)
    const fileSizeProgressTracker = new Array(files.length)
    return (index, progress) => {
        fileSizeProgressTracker[index] = files[index].size / 100 * progress
        const accumulatedProgress = fileSizeProgressTracker.reduce((acc, sizeUploaded) => acc += sizeUploaded, 0)
        const totalProgress = Math.round(100 / totalUploadSize * accumulatedProgress)
        return totalProgress >= 100 ? 0 : totalProgress
    }
}

export const processFiles = async (socket, roomUUID, token, files, lifetime, totalProgressCallback) => {
    try {
        const fileMessages = await Promise.all(files.map((file) => createFile({
            roomUUID,
            name: file.name,
            type: file.type,
            size: file.size,
            lifetime,
            token
        })))
        const presignedUploadPayloads = await Promise.all(fileMessages.map(message => getPresignedURL(message)))
        const getTotalUploadSizePercentage = calculateTotalUploadSize(files)
        await Promise.all(presignedUploadPayloads.map((presignedURLPayload, i) => {
            return fileUploadS3(presignedURLPayload.uploadURL, files[i], progress => {
                socket.emit('file-progress', { roomUUID, fileUUID: presignedURLPayload.uuid, progress })
                const totalProgress = getTotalUploadSizePercentage(i, progress)
                totalProgressCallback(totalProgress)
            })
        }))
    } catch (err) {
        console.log(err);
    }
}