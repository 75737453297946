import * as types from "../type/types";

export const setSocket = (socket) => ({
    type: types.SET_ROOM_SOCKET,
    payload: socket
})

export const setInfo = (info) => ({
    type: types.SET_ROOM_INFO,
    payload: info
})

export const setList = (rooms) => ({
    type: types.SET_ROOM_LIST,
    payload: rooms
})