import './FileList.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import File from './File/File'
import {upsertFileListItem} from '../../../action/file'

const FileList = () => {
    const dispatch = useDispatch()
    const files = useSelector(state => state.files)
    const socket = useSelector(state => state.roomSocket)
    const translation = useSelector(state => state.translation)

    useEffect(() => {
        if (socket && socket.connected) {
            socket.on('file-progress', (payload) => dispatch(upsertFileListItem(payload)))
        }
    }, [socket, dispatch])

    return <div className="filelist">
        {!Array.isArray(files) || files.length === 0 ? <div className="no-files-yet">{translation['filelist.no-files-yet']}</div> : ''}
        {Array.isArray(files) && files.length > 0 ? files.map((file, i) => <File key={file.uuid} index={i} file={file} />) : ''}
    </div>
}

export default FileList