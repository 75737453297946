import { useSelector } from "react-redux"
import Limitation from './Limitation/Limitation'

const UploadButton = ({ upload }) => {

    const translation = useSelector(state => state.translation)

    return <div className="upload-filelist-upload-button">
        <Limitation>
            <div className="start-upload button" onClick={upload}>{translation['filelist.start-upload']}</div>
        </Limitation>
    </div>

}

export default UploadButton