import './Chat.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import Message from './Message/Message'
import Input from './Input/Input'
import { getUUID } from '../../../utility'
import closeIcon from '../../../assets/image/close.png'
import chatIcon from '../../../assets/image/Chat/chat.png'


const Chat = () => {
    const socket = useSelector(state => state.roomSocket)
    const translation = useSelector(state => state.translation)
    const [messages, setMessages] = useState([])
    const messagesEndRef = useRef(null)
    const [isMinified, setIsMinified] = useState(true)

    const scrollDown = () => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

    const openChat = () => {
        setIsMinified(false)
        scrollDown()
    }

    useEffect(() => {

        if (socket && socket.connected) {
            socket.on('chat-message-list', payload => {
                setMessages(payload.messages)
                scrollDown()
            })
            socket.on('chat-message', payload => {
                setMessages([...messages, payload.message])
                scrollDown()
            })
            socket.on('remove-chat-message', payload => {
                setMessages(messages.filter(message => message.uuid !== payload.uuid))
            })
        }
    }, [socket, messages, messagesEndRef])

    if(isMinified) {
        return <div className="chat-minified">
            <div className="open-wrapper" onClick={() => openChat()}><img src={chatIcon} alt="Chat"/></div>
        </div>
    }
    return <div className="chat">
        <div className="header">
            <div className="title">{translation['room.chat.title']}</div>
            <span className="hint">{translation['room.chat.hint']}</span>
            <span className="close"><img onClick={() => setIsMinified(true)} src={closeIcon} alt="Close"/></span>
        </div>
        <div className="message-list">
            {messages.map(message => <Message key={getUUID()} message={message} />)}
            <div ref={messagesEndRef} />
        </div>
        <div className="input-wrapper">
            <Input />
        </div>
    </div>
}

export default Chat