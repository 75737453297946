import './Activation.scss'
import Form from 'react-bootstrap/Form'
import fetch from '../../../../fetch'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Activation = ({ mail, next }) => {

    const [code, setCode] = useState('')
    const [error, setError] = useState(null)
    const translation = useSelector(state => state.translation)

    const submit = async (e) => {
        e.preventDefault()
        setError(null)
        if (code.length > 0 && mail) {
            const { status } = await fetch('/auth/activate-account', { mail, code })
            if (status) {
                next()
            } else {
                setError(translation['activation.error.wrong-code'])
            }
        } else {
            setError(translation['activation.error.unknown-error'])
        }
    }

    return <div id="registration-form">
        <div className="form-wrapper">
            <form onSubmit={submit}>
                <div className="label">{translation['activation.label']}</div>
                <Form.Group controlId="activation.code">
                    <Form.Control type="text" placeholder={translation['activation.code']} value={code} onChange={e => setCode(e.target.value)} />
                </Form.Group>
                <button type="type" className="button">{translation['registration.next']}</button>
            </form>
            {error ? <div className="error">{error}</div> : ''}
        </div>
    </div>
}

export default Activation