import * as types from "../type/types";
import * as user from "./user";
import * as translation from './translation'
import * as file from './file'
import * as room from './room'
import en, { langKey } from './translationFiles/en'


const defaultState = {
  translation: en,
  token: null,
  user: null,
  currentLang: langKey,
  uploadFiles: [],
  totalUploadProgress: 0,
  files: [],
  roomSocket: null,
  roomInfo: null,
  roomList: null,
  processFiles: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action);
    case types.SET_USER:
      return user.set(state, action);
    case types.SET_TRANSLATION:
      return translation.set(state, action);
    case types.RESET_UPLOAD_FILES:
      return file.resetUploads(state);
    case types.ADD_UPLOAD_FILES:
      return file.addUploads(state, action);
    case types.REMOVE_FILE_FROM_UPLOAD:
      return file.removeFromUpload(state, action);
    case types.SET_TOTAL_UPLOAD_PROGRESS:
      return file.setTotalUploadProgress(state, action);
    case types.SET_FILE_LIST:
      return file.setFileList(state, action);
    case types.UPSERT_FILE_LIST_ITEM:
      return file.upsertFileListItem(state, action);
    case types.SET_ROOM_SOCKET:
      return room.setSocket(state, action);
    case types.SET_ROOM_INFO:
      return room.setInfo(state, action);
    case types.SET_ROOM_LIST:
      return room.setList(state, action)
    default:
      return state;
  }
}

export default reducer;
