import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './InfoEdit.scss'

const InfoEdit = ({ close }) => {
    const translation = useSelector(state => state.translation)
    const roomInfo = useSelector(state => state.roomInfo)
    const roomSocket = useSelector(state => state.roomSocket)
    const token = useSelector(state => state.token)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        if (roomInfo) {
            setName(roomInfo.name)
            setDescription(roomInfo.description)
        }
    }, [roomInfo])

    const saveInfo = () => {
        if (token && roomSocket && roomSocket.connected) {
            roomSocket.emit('room-info-update', { roomUUID: roomInfo.uuid, token, update: { name, description, password } })
            close()
        }
    }

    const removePassword = () => {
        if (token && roomSocket && roomSocket.connected) {
            roomSocket.emit('room-info-update', { roomUUID: roomInfo.uuid, token, update: { removePassword: true } })
            close()
        }
    }


    return <div id="room-infoedit">
        <div className="modal-wrapper">
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{translation['room-info.title']}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="room.name">
                        <FormLabel>{translation['room-info.name.label']}</FormLabel>
                        <Form.Control maxLength={100} type="text" placeholder={translation['room-info.name.placeholder']} value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="room.description">
                        <FormLabel>{translation['room-info.description.label']}</FormLabel>
                        <Form.Control maxLength={1000} type="text" placeholder={translation['room-info.description.placeholder']} value={description} onChange={e => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="room.password">
                        <FormLabel>{translation['room-info.password.label']}</FormLabel>
                        <Form.Control maxLength={100} type="text" placeholder={translation['room-info.password.placeholder']} value={password} onChange={e => setPassword(e.target.value)} />
                        <div className="remove-password-wrapper">
                            <div className="remove-password" onClick={removePassword}>{translation['room-info.remove-password']}</div>
                        </div>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>{translation['room-info.close']}</Button>
                    <Button variant="danger" onClick={saveInfo}>{translation['room-info.save']}</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
        <div className="filter"></div>
    </div>
}

export default InfoEdit