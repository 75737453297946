import './Message.scss'
import { getPrettyDateTime } from '../../../../utility'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Message = ({ message }) => {
    const translation = useSelector(state => state.translation)
    const socket = useSelector(state => state.roomSocket)
    const token = useSelector(state => state.token)
    const [metaToggle, setMetaToggle] = useState(false)

    const remove = () => {
        if (socket && socket.connected) {
            socket.emit('remove-chat-message', { token, messageUUID: message.uuid })
        }
        // implement the removal of messages
        // show deletion icon on hover over the message

        // limit the input amount
    }

    return <div className='chat-message'>
        <div className="content" onClick={() => setMetaToggle(!metaToggle)}>{message.message}</div>
       {metaToggle &&  <div className="meta">
            <div className="valid-until">{translation['room.chat.message.valid-until']}{getPrettyDateTime(new Date(message.validUntil))}</div>
            <div className="remove" onClick={remove}>{translation['room.chat.message.remove']}</div>
        </div>}
    </div>
}

export default Message
