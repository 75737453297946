import './Form.scss'
import Form from 'react-bootstrap/Form'
import fetch from '../../../../fetch'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Registration = ({ next }) => {

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [mail, setMail] = useState('')
    const [mailRepeat, setMailRepeat] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const translation = useSelector(state => state.translation)

    const submit = async (e) => {
        e.preventDefault()
        setError(null)
        if (firstname.length > 0 && lastname.length > 0 && mail.length > 0 && mailRepeat.length > 0 && mail === mailRepeat && password.length > 0) {
            const { status, message } = await fetch('/auth/registration', { firstname, lastname, mail, password })
            if (status) {
                next(mail)
            } else {
                if (message && message === 'duplicate-mail') {
                    setError(translation['registration.error.mail-in-use'])
                } else {
                    setError(translation['registration.unknown-error'])
                }
            }
        } else {
            setError(translation['registration.error.check'])
        }
    }

    return <div id="registration-form">
        <div className="form-wrapper">
            <form onSubmit={submit}>
            <Form.Group controlId="registration.firstname">
                <Form.Control type="text" placeholder={translation['registration.firstname']} value={firstname} onChange={e => setFirstname(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="registration.lastname">
                <Form.Control type="text" placeholder={translation['registration.lastname']} value={lastname} onChange={e => setLastname(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="registration.email">
                <Form.Control type="email" placeholder={translation['registration.mail']} value={mail} onChange={e => setMail(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="registration.email.repeat">
                <Form.Control type="email" placeholder={translation['registration.mail.repeat']} value={mailRepeat} onChange={e => setMailRepeat(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="registration.password">
                <Form.Control type="password" placeholder={translation['registration.password']} value={password} onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <button type="submit" className="button">{translation['registration.next']}</button>
            </form>
            {error ? <div className="error">{error}</div> : ''}
        </div>
    </div>
}

export default Registration