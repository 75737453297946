import './NoSuchRoom.scss'
import image from '../../../assets/image/Room/no-such-room.svg'
import {useSelector} from 'react-redux'

const NoSuchRoom = () => {
    const translation = useSelector(state => state.translation)

    return <div id="no-such-room">
        <div className="title">{translation['room.no-such-room']}</div>
        <div className="image"><img src={image} alt="No room found"/></div>
    </div>
}

export default NoSuchRoom