import { v4 as uuidv4 } from 'uuid';
const currencyFormatter = require('currency-formatter');
const prettyBytes = require('pretty-bytes');

export const projectName = 'DROP - BETA'

export const backendDomain = process.env.NODE_ENV === 'production' ? 'https://drop-backend.think-internet.de' : 'http://localhost:8100'
export const backendSocketIODomain = process.env.NODE_ENV === 'production' ? 'https://drop-backend.think-internet.de' : 'http://localhost:8100'

export const getUUID = () => uuidv4()

export const getQueryString = (string, requiredString) => {
    if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
        string = string.substring(1)
        const split = string.split('=')
        return !string.includes('&') && split.length === 2 && split[0] === requiredString && split[1].length > 0 ? decodeURIComponent(split[1]) : false
    } else {
        return false
    }
}

export const getPresignedUploadURLFromS3BucketEndpoint = 'https://fkvrlytyel.execute-api.eu-central-1.amazonaws.com/prod/upload'
export const getPresignedDownloadURLFromS3BucketEndpoint = 'https://fkvrlytyel.execute-api.eu-central-1.amazonaws.com/prod/download'

export const frontendDomain = process.env.NODE_ENV === 'production' ? 'https://drop.think-internet.de' : 'http://localhost:3000'

export const stripe_publishable_key = 'pk_test_urVyINgwVNAjEtQkYlttDRwS00NtROzWoB'

export const getPrettyDateTime = date => {
    // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
    const fill = number => {
        number = `${number}`
        return number.length === 1 ? `0${number}` : number
    }
    const day = fill(date.getDate())
    const month = fill(date.getMonth() + 1)
    const year = date.getFullYear()
    const minute = fill(date.getMinutes())
    const hour = fill(date.getHours())
    return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = date => {
    const fill = number => {
        number = `${number}`
        return number.length === 1 ? `0${number}` : number
    }
    const day = fill(date.getDate())
    const month = fill(date.getMonth() + 1)
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
}

export const formatFileSize = (sizeInBytes, locale = 'de') => {
    return prettyBytes(sizeInBytes, {locale});
}

export const formatPrice = (priceInCents, locale = 'de-DE') => {
    return currencyFormatter.format(priceInCents / 100, { locale });
}