import './File.scss'
import {useDispatch, useSelector} from 'react-redux'
import {removeFileFromUpload} from '../../../../action/file'
import {formatFileSize} from '../../../../utility'

const File = ({file, index}) => {

    const dispatch = useDispatch()
    const translation = useSelector(state => state.translation)

    return <div className={`upload-filelist-file ${index % 2 === 0 ? 'gray' :''}`}>
        <div className="name">{file.name}</div>
        <div className="size">{formatFileSize(file.size)}</div>
        <div className="remove-wrapper">
            <div className="remove button" onClick={() => dispatch(removeFileFromUpload(index))}>{translation['filelist-upload.remove-file']}</div>
        </div>
    </div>
}

export default File
