import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './Dropzone.scss'
import { useDispatch } from 'react-redux'
import { addUploads } from '../../../action/file'
import DragInactive from './DragInactive/DragInactive'
import DragActive from './DragActive/DragActive'

const Dropzone = () => {

    const dispatch = useDispatch()

    const onDrop = useCallback(files => dispatch(addUploads(files)), [dispatch])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return <div className="dropzone">
        <div {...getRootProps()} className="dragable">
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <DragActive/> :
                    <DragInactive/>
            }
        </div>
    </div>
}

export default Dropzone