import fetch from '../../../../fetch'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import './Stripe.scss'

const Stripe = ({ planCode, finish }) => {

    const stripe = useStripe();
    const elements = useElements();
    const token = useSelector(state => state.token)
    const translation = useSelector(state => state.translation)
    const [billingDetail, setBillingDetail] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const createSubscription = async () => {
            const { status, clientSecret, billingDetail } = await fetch('/payment/stripe/subscription/create', { token, planCode })
            if (status && clientSecret && billingDetail) {
                setClientSecret(clientSecret)
                setBillingDetail(billingDetail)
            } else {
                setError(translation['account.payment.stripe.subscription.create.error'])
            }
        }

        const createCustomer = async () => {
            const { status } = await fetch('/payment/stripe/customer/create', { token })
            if (status) {
                createSubscription()
            } else {
                setError(translation['account.payment.stripe.customer.create.error'])
            }
        }

        if (token && planCode && !clientSecret) {
            createCustomer()
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        const cardElement = elements.getElement(CardElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: billingDetail
          }
        });
    
        if(error) {
          setError(error.message);
          setClientSecret(null)
          return;
        } else if(paymentIntent.status !== 'succeeded') {
            setError(translation['account.payment.stripe.unexpected.error'])
            setClientSecret(null)
            return;
        }
        finish()
    }

    if(error) return <div id="stripe"><div className="error">{error}</div></div>

    if(clientSecret && billingDetail) {
        return  <form id="stripe" onSubmit={handleSubmit}>
        <CardElement />
        <div className="button-wrapper"><button type="submit" className="button">{translation['account.payment.finish']}</button></div>
      </form>
    }
    return <div id="stripe"><div className="loading">{translation['account.payment.stripe.loading']}</div></div>
}

export default Stripe